<template>
  <div class="page-div">
    <div class="line-div" v-for="(item, index) in info[routerIndex]" :key="index" @click="routerSkip(index)">
      <div class="line-div_name">{{ item.name }}</div>
      <img class="line-img_right" src="@/assets/images/app/right.png" />
    </div>
  </div>
</template>

<script>
  import { kind as infoData, List } from './data/PersonalInfoCollection';
  export default {
    data() {
      return {
        info: infoData,
        list: List,
        routerIndex: this.$router.currentRoute.query.id,
      };
    },
    mounted() {
      // console.log(`mounted`);
      document.title = this.list[this.routerIndex].name;
      // this.$sdk.appCall(this.$sdk.callMethod.SET_TITLE, this.list[this.routerIndex].name);
      // this.$sdk.register(this.$sdk.listenMethod.ON_SHOW, () => {
      //   this.$sdk.appCall(this.$sdk.callMethod.SET_TITLE, this.list[this.routerIndex].name);
      // });
    },
    methods: {
      routerSkip(index) {
        window.location.href =
          window.location.origin + `/music-lib/personal-info-collect/content?listId=${this.routerIndex}&kindId=${index}`;
      },
    },
  };
</script>
<style scoped>
  .line-img_right {
    width: 0.32rem;
    height: 0.32rem;
  }

  .page-div {
    display: flex;
    flex-flow: column nowrap;
    margin: auto 0.3rem;
  }

  .line-div_name {
    font-size: 0.32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }

  .line-div {
    height: 1rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    border-bottom: 0.01rem solid #ebebeb;
  }
</style>
